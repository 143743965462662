import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useLazyQuery, gql } from '@apollo/client';
import { transparentize } from 'polished';

import { getComponentSettings, removeSpecialCharacters } from 'libs/content';
import MediumWidth from 'layouts/medium-width';
import Button from 'components/forms/Button';
import Spacing from 'layouts/Spacing';
import { AnchorLink } from 'components/Link';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Box from 'components/forms/Box';
import Loading from 'components/Loading';

const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

const ListItem = styled.li`
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: 20px;
	&:nth-child(odd) {
		background-color: ${p => transparentize(0.6, p.theme.colors.blue100)};
	}
`;

const Wrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	${p =>
		p.theme.media.XSmallOnly(css`
			flex-direction: column;
			align-items: flex-start;
		`)}
`;

const ColumnLeft = styled.div`
	width: calc(100% - 120px);
	${p =>
		p.theme.media.XSmallOnly(css`
			width: 100%;
		`)}
	a {
		font-weight: 600;
	}
`;

const ColumnRight = styled.div`
	width: 120px;
	${p =>
		p.theme.media.smallOnly(css`
			a.small {
				justify-content: center;
			}
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			width: 100%;
			margin-top: 5px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column-reverse;
		`)}
`;

const Desc = styled.div`
	margin-top: 0;
	display: block;
	span {
		&:first-of-type:not(:last-of-type):after {
			content: ', ';
		}
	}
`;

const Deadline = styled.span`
	font-size: 14px;
	line-height: 20px;
	margin-top: 5px;
	display: block;
	${p =>
		p.theme.media.XSmallOnly(css`
			margin-top: 0px;
			margin-bottom: 10px;
		`)}
`;

/**
 * Represents a list of job vacancies from Teamtailor.
 * @param {string} id - The id of the component
 * @param {string} title - The title above the list
 * @param {string} intro - The introduction text above the list
 * @param {string} keyword - The keywords to filter the list results
 * @param {array} settings - The component settings
 * @param {string} target - The button´s target (to open in a new browser tab or not)
 * @param {string} headinglevel - The title´s heading level
 * @param {...object} props - Rest of the parameters for the component
 */
export default function JobVacancies({
	id = 'ledige-stillinger',
	title = '',
	intro = '',
	keyword = '',
	settings = [],
	headinglevel = 'h2',
	...props
}) {
	const [loading, setLoading] = useState(true);
	const [jobs, setJobs] = useState([]);
	const [filteredJobs, setFilteredJobs] = useState([]);

	const componentSettings = getComponentSettings({ settings, props });

	const [listTeamtailorJobs, { loading: loadingJobs }] = useLazyQuery(
		gql`
			query {
				jobs: listTeamtailorJobs {
					title
					department
					active
					deadline
					url
					urlApply
					startDate
					endDate
				}
			}
		`
	);

	useEffect(() => {
		const fetchJobsAndFilter = async () => {
			setLoading(true);

			const response = await listTeamtailorJobs();

			// If no keyword is provided, set jobs and stop loading
			if (!loadingJobs && response?.data?.jobs?.length !== 0) {
				setJobs(response.data.jobs);
			}

			// If no keyword is provided, stop loading and return
			if (
				!keyword ||
				(!loadingJobs && response?.data?.jobs?.length === 0)
			) {
				setLoading(false);
				return;
			}

			// Split the search words and remove whitespaces
			const searchWords = keyword?.replace(/\s/g, '').split(',') || [];

			// Filter the jobs based on the search words
			const filtered = response?.data?.jobs?.reduce((acc, job) => {
				const isMatch = searchWords.some(word => {
					const lowerCaseWord = word.toLowerCase();
					return (
						job?.title.toLowerCase().includes(lowerCaseWord) ||
						job?.department?.includes(keyword)
					);
				});

				if (isMatch) {
					acc.push(job);
				}

				return acc;
			}, []);

			// Set the filtered jobs
			if (filtered?.length !== 0) setFilteredJobs(filtered);

			setLoading(false);
		};

		fetchJobsAndFilter();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyword, jobs?.length]);

	const combinedJobs = filteredJobs?.length > 0 ? filteredJobs : jobs;

	// If hideifnovacancies is true and no vacancies are found, return null
	if (
		componentSettings?.hideifnovacancies === 'true' &&
		!loading &&
		!!(
			componentSettings?.hideifnovacancies === 'true' &&
			keyword &&
			jobs?.length === 0 &&
			filteredJobs?.length === 0
		)
	) {
		return null;
	}

	// If loading show loading spinner
	if (loading) return <Loading />;

	return (
		<Spacing
			className="component__job-vacancies"
			data-cy="component__job-vacancies"
			{...props}>
			<MediumWidth
				id={title ? removeSpecialCharacters(title) : id}
				className="medium-width">
				{componentSettings?.hidetitle !== 'true' && (
					<TitleAndText
						title={title}
						text={intro}
						headinglevel={headinglevel}
						settings={settings}
						nested={true}
						spacing={{ bottom: 'none' }}
						style={{ marginBottom: '20px' }}
					/>
				)}
				{(!loading && combinedJobs?.length > 0 && (
					<div id="stillinger">
						{keyword && !filteredJobs?.length > 0 && (
							<p style={{ marginBottom: '45px' }}>
								Ingen stillinger innenfor denne kategorien for
								øyeblikket. Se alle andre ledige stillinger
								nedenfor.
							</p>
						)}

						<div>
							<List
								className="job-vacancies"
								id="positions-table-list">
								{combinedJobs?.map(job => {
									return (
										<ListItem key={job?.title}>
											<Wrap>
												<ColumnLeft>
													<AnchorLink
														target="_blank"
														rel="noreferrer"
														title={`Gå til stillingsanonsen for "${job?.title}"`}
														href={job.url}>
														{job?.title}
													</AnchorLink>
													<Desc>
														{job?.department && (
															<span>
																{job.department}
															</span>
														)}
													</Desc>
												</ColumnLeft>
												<ColumnRight>
													<Button
														href={
															job?.urlApply ||
															job?.url
														}
														title={`Søk på stillingen "${job?.title}"`}
														small={true}>
														Send søknad
													</Button>
													{job.deadline && (
														<Deadline>
															{`Frist: ${job.deadline}`}
														</Deadline>
													)}
												</ColumnRight>
											</Wrap>
										</ListItem>
									);
								})}
							</List>
						</div>
					</div>
				)) || (
					<Box className="no-vacancies">
						Ingen ledige stillinger akkurat nå.
					</Box>
				)}
			</MediumWidth>
		</Spacing>
	);
}
